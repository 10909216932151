.navbarContainer {
  background-color: #000000;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: fixed;
  top: 0;
  z-index: 5;
}

.logoSection {
  width: 17%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 4%;
  cursor: pointer;
}

.logoSection img {
  width: 75%;
  height: 75%;
}

.navbarItemsContainer {
  display: flex;
  flex-direction: row;
  margin-right: 4%;
  align-items: center;
  justify-content: center;
}

.navbarItem {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  width: auto;
  height: 100%;
  text-align: center;
  color: #ffffff;
  margin-right: 2em;
  margin-left: 2em;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navbarAboutOptionsContainer {
  width: 200px;
  background: #4947c1;
  position: absolute;
  top: 100%;
}

.navbarAboutOptionBox {
  margin: 1em 1em 1em 1em;
}

.navbarAboutOptionName {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  text-align: left;
}

.navbarAboutOptionName:hover {
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  text-align: left;
}

.registerOrLoginBtn {
  width: 160px;
  height: 27.5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 20px;
  align-items: center;
  cursor: pointer;
}

.registerOrLoginBtn img {
  width: 20px;
  height: 15px;
  margin-left: 0.75em;
}

.registerOrLoginBtn div {
  margin-right: 0.75em;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #4947c1;
}
