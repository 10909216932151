.bannerContainer {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5em;
}

.bannerImgStyle {
  height: 100%;
  width: 100%;
}

.bodyLandingPageContainer {
  width: 100%;
  background-color: #f8f8f8;
}

.filterLandingPageContainer {
  width: 100%;
  background-color: #4947c1;
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.filterLabelSearch {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
}

.filterBtnSearch {
  width: 142px;
  height: 39px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.filterBtnSearchLabel {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.filterBtnDropdown {
  /* width: 100%; */
  height: 39px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.filterDropdownValue {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}

.filterInputStyle {
  background-color: #4947c1;
  border: 1px solid #ffffff;
  border-radius: 10px;
  width: 400px;
  height: 39px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  padding: 0 0.75em 0 0.75em;
}

::placeholder {
  color: #ffffff;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ffffff;
}
