.bodyAboutContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.firstSectionAboutPage {
  text-align: left;
  margin-left: 5em;
  margin-top: 3em;
  width: 60%;
}

.firstSectionAboutPageText {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #000000;
}

.ourGuidingPrinciplesContainer {
  margin-top: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #f8f8f8;
}

.ourGuidingPrinciplesIcon {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
  background-color: #d9d9d9;
  margin-bottom: 2em;
}

.ourGuidingPrinciplesContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 2.5em;
}

.ourGuidingPrinciplesContentBox {
  margin-right: 2em;
  display: flex;
  flex-direction: column;
}

.ourGuidingPrinciplesContentText {
  text-align: left;
  width: 100%;
}

.missionAndValuesContainer {
  margin-top: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #ffffff;
}

.missionAndValuesBody {
  width: 80%;
  margin-top: 2.5em;
  margin-bottom: 2.5em;
  margin-left: 5em;
  /* margin-right: 5em; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.missionAndValuesTitle {
  text-align: left;
  width: 60%;
}

.missionAndValuesLabel {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #9f7811;
  margin-top: 1em;
}

.missionAndValuesTitleIcon {
  margin-top: -2.5em;
}

.missionAndValuesTitleIcon img {
  width: 406px;
  height: 406px;
}

.missionAndValuesDescBody {
  text-align: left;
  margin-left: 5em;
  width: 60%;
}

.lastSectionAboutPage {
  margin: 5em 5em 5em 5em;
  display: flex;
  flex-direction: row;
  height: 480px;
}

.firstBoxLastSectionAboutPage {
  width: 45%;
  background-color: #f8f8f8;
}

.firstBoxLastSectionAboutPage img {
  width: 100%;
  height: 100%;
}

.secondBoxLastSectionAboutPage {
  width: 55%;
  background-color: #9f7811;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.secondBoxLastSectionAboutPageTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #ffffff;
  text-align: left;
  margin-left: 1.5em;
}

.secondBoxLastSectionAboutPageDesc {
  width: 50%;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  margin-left: 3em;
  margin-top: 1.5em;
  text-align: left;
}

.secondBoxLastSectionAboutPageBtn {
  width: 164px;
  height: 39px;
  margin-left: 3.5em;
  margin-top: 3em;
  background-color: #030084;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.secondBoxLastSectionAboutPageBtn div {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}

.aboutNavigationContainer {
  text-align: left;
  margin-left: 5em;
  margin-top: 4.5em;
  display: flex;
  flex-direction: row;
}

.labelNavigationAboutContainer {
  display: flex;
  flex-direction: row;
}

.labelNavigationAboutContainer div {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #4947c1;
}

.membersContainer {
  margin-top: 2em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.membersBox {
  background-color: #ffffff;
  width: 28.2%;
  height: 500px;
  margin-right: 2em;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.memberImageBox {
  width: 200px;
  height: 200px;
  border-radius: 100px;
}

.memberImageBox img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 100px;
}

.memberName {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #000000;
  margin-top: 2em;
}

.memberDescription {
  margin-top: 2em;
  margin-left: 1.5em;
  margin-right: 1.5em;
}
