.articleProcessingChargesContainer {
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.articleProcessingChargesUpperContent {
  width: 100%;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.articleProcessingChargesLabel {
  margin-top: 1.5em;
  margin-left: 2.5em;
  margin-right: 5em;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #4947c1;
}

.articleProcessingChargesBodyContent {
  display: flex;
  flex-direction: column;
  margin-left: 5em;
  margin-right: 5em;
  margin-bottom: 3em;
}

.articleProcessingDesc {
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  width: 66%;
}

.apcPublishingOperations {
  margin-top: 1em;
  text-align: left;
}

.apcPublishingOperationBold {
  font-weight: 700;
  font-size: 16px;
}

.apcTableContainer {
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  width: 66%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.apcTableContainer table,
thead,
tbody,
tr,
td {
  text-align: left;
}

/* .apcTableContainer table {
  background-color: #cccccc;
} */

.apcTableContainer th,
td {
  border: 1px solid #989898;
}

.apcTheadNumberTh {
  width: 50px;
}

.apcTheadServiceCoverTh {
  width: 250px;
}

.apcTheadAmountTh {
  width: 150px;
}

.apcTbodyNumberTd {
  text-align: center;
}
