.journalContainer {
  margin-top: 1.5em;
  margin-left: 5em;
  margin-right: 5em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.journalLabel {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #4947c1;
}

.journalList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1em;
}

.journalBox {
  background-color: #ffffff;
  width: 48.5%;
  height: 480px;
  margin-top: 2em;
  margin-bottom: 2em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.journalContent {
  width: 87.5%;
  height: 82.5%;
}

.journalImgAndTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.journalImg {
  width: 180px;
  height: 250px;
  background-color: #d9d9d9;
}

.journalImg img {
  width: 100%;
  height: 100%;
}

.journalTitleContainer {
  width: 60%;
  position: relative;
}

.journalTags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tagBox {
  border-radius: 20px;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tagName {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: left;
}

.journalTitle {
  margin-top: 0.5em;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #9f7811;
  text-align: left;
}

.journalTitle:hover {
  text-decoration: underline;
  cursor: pointer;
}

.journalLastUpdated {
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #5b5b5b;
  position: absolute;
  bottom: 0;
}

.journalOwners {
  margin-top: 2em;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.journalUserBox {
  display: flex;
  flex-direction: row;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.journalOrchidIdLogo {
  width: 14px;
  height: 14px;
  margin-top: -0.1em;
  margin-left: 0.2em;
}

.journalPlaces {
  margin-top: 2em;
}

.journalPlaceBox {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;
}

.journalPlaceNumber {
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
  color: #000000;
  margin-right: 0.5em;
}

.journalPlaceStreetName {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
}

.whyPublishWithUsContainer {
  margin-top: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #ffffff;
}

.whyPublishWithUsBody {
  margin-top: 2.5em;
  margin-bottom: 2.5em;
  margin-left: 5em;
  margin-right: 5em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.whyPublishWithUsLabel {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #4947c1;
}

.whyPublishWithUsContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 2.5em;
}

.whyPublishWithUsContentBox {
  margin-right: 1em;
}

.whyPublishWithUsContenIcon {
  height: 250px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sliderBanner {
  width: 100%;
  height: 480px;
}

.sliderImageStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 480px;
}

.sliderImageStyle img {
  width: 100%;
  height: auto;
}

.footerContainer {
  position: absolute;
  width: 100%;
}

.footerNavigationContainer {
  background-color: #000000;
  color: white;
}

.backToTop {
  display: flex;
  text-align: left;
  margin-left: 35px;
  padding-top: 32px;
}

.backToTop a {
  color: white;
  text-decoration: none;
  margin-left: 10px;
  font-size: 12px;
}

.footerNavigationMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
  width: 100%;
}

.footerNavigationMainList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-left: 2em;
}

.footerNavigationMainList ul {
  margin: 0px 15px 60px;
}

.footerNavigationMainList li {
  color: white;
  font-size: 15px;
  cursor: pointer;
}

.footerNavigationMainList a {
  text-decoration: none;
  color: white;
  font-size: 15px;
}

.footerNavigationSubscribe {
  margin-top: -30px;
  margin-right: 4em;
}

.footerNavigationSubscribeMainText {
  font-weight: 700;
  font-size: 20px;
}

.footerNavigationSubscribeSubText {
  font-weight: 400;
  font-size: 15px;
}

.footerNavigationSubscribeSocialMedia img {
  margin: 69px 22px 50px 0px;
}

.footerCopyright {
  background-color: black;
  color: white;
}

.footerCopyrightContainer {
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.footerCopyright p {
  padding: 8px;
  margin-left: 61px;
}

.footerCopyright ul {
  margin-right: 48px;
}

.footerCopyright li {
  color: white;
}

.emptyJournalAfterFiltered {
  margin-top: 1.5em;
  margin-bottom: 1em;
}

.emptyJournalAfterFiltered {
  text-align: left;
}

.emptyJournalLabel {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #000000;
  margin-bottom: 2em;
}

.emptyJournalDesc {
  display: flex;
  flex-direction: row;
}

.emptyJournalDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #000000;
  margin-bottom: 20em;
}

.existJournalLabel {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.existJournalLabelTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #000000;
}

.leftFilterBox {
  margin-top: 1.5em;
  margin-bottom: 2em;
  width: 20%;
  height: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}

.leftFilterBoxTitle {
  background-color: #000000;
  width: 100%;
  height: 56px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.leftFilterBoxTitleLabel {
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-align: left;
  margin-left: 1.5em;
}

.leftFilterBoxOptions {
  margin-left: 1.5em;
  margin-right: 1.5em;
  text-align: left;
  margin-bottom: 1em;
}

.leftFilterBoxOptionsTitle {
  margin-top: 1.5em;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #4947c1;
  margin-bottom: 1em;
}

.journalListAfterFileteredContainer {
  width: 75%;
}

.journalBoxAfterFiltered {
  width: 100%;
  margin-top: 1.5em;
  margin-bottom: 3.5em;
}

.journalTitleContainerAfterFiltered {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
}

.journalTitleAfterFiltered {
  margin-top: 1em;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #9f7811;
}

.journalTitleAfterFiltered:hover {
  text-decoration: underline;
  cursor: pointer;
}

.journalDescAfterFiltered {
  margin-top: 1em;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 244px;
}

.journalLastUpdatedAfterFiltered {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #5b5b5b;
  margin-top: 1em;
}

.journalOwnersAfterFiltered {
  margin-top: 1em;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.journalPlacesAfterFiltered {
  margin-top: 1em;
}

.paginationAndFilterSortbyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.paginationContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 1.5em;
}

.paginationLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  margin-right: 1em;
}

.paginationBodyContainer {
  border: 1px solid #000000;
  border-radius: 8px;
}

.paginationBody {
  margin: 0.3em 0.5em 0.3em 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 45px;
}

.paginationValue {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #000000;
}

.filterSortByMainContainer {
  position: relative;
}

.filterSortByContainer {
  border: 1px solid #000000;
  border-radius: 8px;
  cursor: pointer;
}

.filterSortByBody {
  margin: 0.3em 0.75em 0.3em 0.75em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 150px;
}

.filterSortByIconAndLabel {
  display: flex;
  flex-direction: row;
}

.filterSortByValue {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

.divInputCheckbox {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.75em;
  align-items: flex-start;
}

.divInputCheckbox div {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-top: -0.3em;
  margin-left: 0.5em;
}

.bottomPaginationContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 5em;
}

.previousPageBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  width: 100%;
  cursor: pointer;
}

.pageValue {
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  width: 100%;
}

.nextPageBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  width: 100%;
  cursor: pointer;
}

.dropdownBoxSortby {
  position: absolute;
  z-index: 10;
  margin-top: 0.5em;
  border: 1px solid #000000;
  border-radius: 8px;
  background-color: #ffffff;
  text-align: left;
}

.dropdownBoxCategoryAndTopic {
  position: absolute;
  z-index: 10;
  margin-top: 0.5em;
  border: 1px solid #ffffff;
  border-radius: 8px;
  background-color: #4947c1;
  text-align: left;
}

.dropdownBodySortby {
  margin: 0.3em 0.75em 0.3em 0.75em;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 150px;
  text-align: left;
}

.dropdownSortbyOption {
  margin-top: 1em;
  margin-bottom: 1em;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  text-align: left;
  cursor: pointer;
}

.dropdownCategoryAndTopic {
  margin-top: 1em;
  margin-bottom: 1em;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  text-align: left;
  cursor: pointer;
}

.journalDetailContainer {
  margin-left: 5em;
  margin-top: 6em;
}

.journalDetailUpperContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.journalDetailRightBox {
  width: 25%;
  margin-right: 5em;
  margin-top: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* position: fixed;
  left: 75%; */
  position: relative;
}

.journalDetailRightBoxDownloadBtn {
  width: 227px;
  height: 39px;
  background-color: #030084;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.journalDetailRightBoxDownloadBtn div {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}

.journalDetailRightBoxDescription {
  margin-left: 2em;
  margin-right: 2em;
  width: 73%;
  text-align: left;
}

.journalDetailRightBoxDescriptionSection {
  padding-top: 1em;
  padding-bottom: 1em;
}

.journalDetailRightBoxDescriptionSectionLabel {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
}

.divider {
  border-top: 1px solid #5b5b5b;
}

.journalDetailRightBoxDescriptionShareOnSection {
  display: flex;
  flex-direction: row;
  margin-top: 0.5em;
}

.journalDetailRightBoxDescriptionShareOnSection img {
  width: 26px;
  height: auto;
}

.journalDetailRightBoxDescriptionShareOnSection a {
  margin-right: 1em;
}

.journalDetailRightBoxDescriptionSectionArticleViewCount {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-top: 0.5em;
}

.journalDetailRightBoxDescriptionSectionAcademicEditor {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5em;
}

.journalDetailRightBoxDescriptionSectionAcademicEditorImageProfile img {
  width: 25px;
  height: 25px;
}

.journalDetailRightBoxDescriptionSectionAcademicEditorNameDesc {
  margin-left: 0.5em;
}

.journalDetailRightBoxDescriptionSectionAcademicEditorName {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

.journalDetailRightBoxDescriptionSectionAcademicEditorDesc {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #5b5b5b;
}

.journalDetailAbstractLabel {
  margin-top: 2.5em;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
}

.journalDetailAbstract {
  text-align: left;
  margin-top: 1em;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 488px;
}

.journalDetailLastUpdated {
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #5b5b5b;
  margin-top: 2em;
}

.journalDetailKeyword {
  margin-top: 2em;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
}

.journalDetailKeywordDesc {
  margin-top: 1em;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

.relatedArticlesContainer {
  background-color: #f8f8f8;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* margin-top: -10em; */
}

.relatedArticlesContainerBody {
  margin-left: 5em;
}

.relatedArticlesLabel {
  margin-top: 2.5em;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #000000;
}

.relatedArticlesContainerList {
  margin-top: 3em;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
}

.relatedArticleBox {
  width: 28%;
  margin-right: 5em;
  margin-bottom: 2em;
}

.relatedArticleBox .divider {
  margin-top: 2em;
}
